import React from 'react';

const IconTelegram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24">
    <title>Telegram</title>
    <path
      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.14-.26.26-.52.26l.206-2.99 5.59-5.04c.24-.213-.054-.334-.373-.121l-6.91 4.34-2.97-.92c-.643-.203-.658-.643.136-.953l11.57-4.46c.537-.213 1.006.14.82.941z"
    />
  </svg>
);

export default IconTelegram;
